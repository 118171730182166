export const numFormat = (number: number) => {
  if(number !== null){
    if (number >= 1e9) {
      return (number / 1e9).toFixed(2) + " B";
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(2) + " M";
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(2) + " K";
    } else {
      return number.toString()
    }
  } else{
    return  number
  }
};

export const strongPassword =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])(?=.{8,})\//;

export const mediumPassword =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{6,}$/;

export const weakPassword = /.+/;

export const nullPassword = /^$/;

export const validateEmail = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

export const isValidInput = (text: any) => {
  if (typeof text !== "string" || text.trim() === "") {
    return false;
  }
  const validTextPattern = /^[\x20-\x7E]*$/; //Allow decimal point
  return validTextPattern.test(text);
};

export const validatePassword = (password: string) => {
  const regex =
    /^(?=.*[a-zA-Z0-9])(?=.*[!@#$%^&*()_+=[\]{}|\\';:/?.>,<])(?=.*\d).{6,}$/;
  return regex.test(password);
};

export function filterChildrenByParent(parents, children) {
  const parentIds = new Set(parents.map((parent) => parent.id));
  return children.filter((child) => parentIds.has(child.id));
}

export const isValidNumber = (number: any) => {
  const regex = /^\d+(\.\d+)?$/;
    const maxNumber = 1e20; // Define a max limit for the number

    // Check if it's a valid number and within the max limit
    return regex.test(number) && number <= maxNumber;
  // return regex.test(number);
};

//this function accept only characters
export const filterOnlyCharacters = (value: string) => {
  const regex = /^[a-zA-Z ]+$/;
  return regex.test(value);
};

export const  validateNegativeValues = (value:any) => {
    
  const regex =  /^-?\d+(\.\d+)?$/;
  return regex.test(value);
}