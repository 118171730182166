import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { useQuery } from "react-query";
import Loader from "src/components/Loader/Loader";
import { useDispatch } from "react-redux";
import { openSnackbar } from "src/redux/snackbar";
import { isValidInput, isValidNumber } from "src/services/common";
import { getMonthlyListenerFactor } from "src/services/monthlyListenerFactor";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Clear } from "@mui/icons-material";


const MonthelyListnesFactor = () => {
  const [factor, setFactor] = useState<any>({
    monthlyListenerFactor: null
  })

  const [calculatedFactor, setCalculatedFactor] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [callApi, setCallApi] = useState(false)

  const dispatch = useDispatch();

  const { isLoading, refetch, isFetching, isRefetching, } =
    useQuery(["instaFollowerFactor", factor?.monthlyListenerFactor], () => getMonthlyListenerFactor(factor?.monthlyListenerFactor), {
      enabled: false,
      onSuccess: (data: any) => {
        setCalculatedFactor(data?.data?.result)
      },
      onError: (error: any) => {
        dispatch(
          openSnackbar({
            message: "Error occures",
            title: "Error",
            type: "error",
          })
        );
      },
    });

  useEffect(() => {
    if (callApi && factor?.monthlyListenerFactor) {
      refetch()
    }
    // on unMount call api will be false 
    return () => {
      setCallApi(false)
    }
  }, [callApi, factor])

  const handleChange = (e) => {
    if (!isValidNumber(e.target.value)) {
      return
    }
    setFactor({
      ...factor,
      monthlyListenerFactor: e.target.value
    })
  }

  const submitHandler = () => {
    if (factor?.monthlyListenerFactor) {
      setCallApi(true)
    }
    setIsSubmitted(true)
  };

  const handleRefresh = () => {
    setFactor({
      ...factor,
      monthlyListenerFactor: ""
    })
    setCalculatedFactor(null);
    setIsSubmitted(false);
  }
  return (
    <>
      <Helmet>
        <title>Monthly Listener Factor</title>
      </Helmet>
      <PageTitleWrapper>


        <Container maxWidth="lg" >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography
              variant="h3"
              sx={{
                textTransform: "capitalize",
                marginLeft: "-25px",

              }}
            >
              Calculate Monthly Listener Factor
            </Typography>
            <Tooltip title="Clear">
              {/* <RefreshIcon onClick={handleRefresh} sx={{ cursor: 'pointer' }} /> */}
              <Clear onClick={handleRefresh} sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </Box>

          <Grid
            container
            direction="row"
            alignItems="stretch"
            spacing={3}
            pt={5}
            gap={5}
          >
            <TextField
              name="monthlyListenerFactor"
              label="Monthly Listener Factor"
              sx={{
                width: "48%",
              }}
              value={factor?.monthlyListenerFactor}
              // onChange={(e) => setFactor({
              //   ...factor,
              //   monthlyListenerFactor: e.target.value
              // })}
              onChange={handleChange}
              type="number"
              error={isSubmitted && !isValidInput(factor.monthlyListenerFactor)}
            />
            <Button
              sx={{
                border: "1px solid black",
                width: "30%",
                backgroundColor: "black",
                color: "white",
                ":hover": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
              onClick={submitHandler}
              disabled={isLoading}
            >
              {
                isLoading || isFetching || isRefetching &&
                <Loader size={30} color={"inherit"} />
              }
              Submit
            </Button>

            <Typography
              variant="h6"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Calculated Media Factor: {calculatedFactor}
            </Typography>

          </Grid>
        </Container>


      </PageTitleWrapper>


    </>
  );
};

export default MonthelyListnesFactor;
