import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { useQuery } from "react-query";
import Loader from "src/components/Loader/Loader";
import { useDispatch } from "react-redux";
import { openSnackbar } from "src/redux/snackbar";
import { isValidInput, isValidNumber } from "src/services/common";
import { getInstaFollowersFactor } from "../../../services/instaFollowers"
import RefreshIcon from '@mui/icons-material/Refresh';
import { Clear } from "@mui/icons-material";

const InstaFollowersFactor = () => {
  const [factor, setFactor] = useState<any>({
    instaFollowerFactor: null
  })
  const [calculatedFactor, setCalculatedFactor] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [callApi, setCallApi] = useState(false)

  const dispatch = useDispatch();

  const { isLoading, refetch, isFetching, isRefetching, } =
    useQuery(["instaFollowerFactor", factor?.instaFollowerFactor], () => getInstaFollowersFactor(factor?.instaFollowerFactor), {
      enabled: false,
      onSuccess: (data: any) => {
        setCalculatedFactor(data?.data?.result)
      },
      onError: (error: any) => {
        dispatch(
          openSnackbar({
            message: "Error occures",
            title: "Error",
            type: "error",
          })
        );
      },
    });

  useEffect(() => {
    if (callApi && factor?.instaFollowerFactor) {
      refetch()
    }
    // on unMount call api will be false 
    return () => {
      setCallApi(false)
    }
  }, [callApi, factor])

  const submitHandler = () => {
    setIsSubmitted(true)
    if (factor?.instaFollowerFactor === null){
      return
    }else if (factor?.instaFollowerFactor < 0) {
        dispatch(
          openSnackbar({
            message: "The factor value can't be negative.",
            title: "Error",
            type: "error",
          })
        );
        return;
      }
    
    if (factor?.instaFollowerFactor) {
      setCallApi(true)
    }

  };

  const handleRefresh = () => {
    setFactor({
      ...factor,
      instaFollowerFactor: ""
    })
    setCalculatedFactor(null);
    setIsSubmitted(false);
  }

  const preventSign = (e) => {
    if (e.code === 'Minus' || e.code === "Equal" || e.code === "NumpadSubtract" || e.code === "NumpadAdd") {
      e.preventDefault();
    }
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    setFactor({
      ...factor,
      // instaFollowerFactor: e.target.value
      instaFollowerFactor: value === "" ? null : parseInt(value),
      
    })
  }

  return (
    <>
      <Helmet>
        <title>Insta Followers Factor</title>
      </Helmet>
      <PageTitleWrapper>


        <Container maxWidth="lg" >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography
              variant="h3"
              sx={{
                textTransform: "capitalize",
                marginLeft: "-25px",
              }}
            >
              Calculate Insta Followers Factor
            </Typography>
            <Tooltip title="Clear">
              <Clear onClick={handleRefresh} sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </Box>

          <Grid
            container
            direction="row"
            alignItems="stretch"
            spacing={3}
            pt={5}
            gap={5}
          >
            <TextField
              name="instaFollowerFactor"
              label="Insta Follower Factor"
              sx={{
                width: "48%",
              }}
              value={factor?.instaFollowerFactor}
              onChange={handleChange}
              type="number"
              // error={isSubmitted && !isValidInput(factor.instaFollowerFactor)}
              // error={isSubmitted && !isValidNumber(factor.instaFollowerFactor)}
              error={isSubmitted && (factor.instaFollowerFactor < 0 || factor.instaFollowerFactor === null)}
              onKeyDown={preventSign}
            />
            <Button
              sx={{
                border: "1px solid black",
                width: "30%",
                backgroundColor: "black",
                color: "white",
                ":hover": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
              onClick={submitHandler}
              disabled={isLoading}
            >
              {
                isLoading || isFetching || isRefetching &&
                <Loader size={30} color={"inherit"} />
              }
              Submit
            </Button>

            <Typography
              variant="h6"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Calculated Media Factor: {calculatedFactor}
            </Typography>

          </Grid>
        </Container>


      </PageTitleWrapper>


    </>
  );
};

export default InstaFollowersFactor;
