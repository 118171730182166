import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface SearchInputProps {
    name: string;
    options: any;
    onChange: (e: any) => void;
    label: string;
    id: string;
    sx: {};
    value: any,
    error?: boolean;
    multiple: boolean;
    isLoading: boolean;
    disableClearable?: boolean;
    isOptionEqualToValue?: any
}

const SearchInput = (props: SearchInputProps) => {
    const {
        name,
        options,
        onChange,
        label,
        sx,
        value,
        error,
        id,
        multiple,
        isLoading,
        disableClearable = false,
    } = props;


    let filteredOptions = options;

    if (multiple && value.length) {
        const notSelectedOptions = options.filter((item) => {
            // Check if the item.label is not in the value array
            return !value.includes(item.label);
        });

        filteredOptions = [...notSelectedOptions];
    }


    if (name === "sub_media") {
        return (
            <Autocomplete
                id={id}
                onChange={(event, newValue) => {
                    onChange(newValue);
                }}
                filterSelectedOptions={true}
                options={isLoading ? [{ label: "loading...", value: "loading" }] : filteredOptions}
                sx={sx}
                renderInput={(params) => <TextField {...params} label={label} error={error} />}
                multiple={multiple}
                loading={isLoading}
                key={id}
                value={value}
                disableClearable={disableClearable}
                getOptionDisabled={(option) => option.value === "loading"}

            />
        )
    }
    return (
        // !isLoading &&
        <Autocomplete
            id={id}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            filterSelectedOptions={true}
            options={filteredOptions}
            sx={sx}
            renderInput={(params) => <TextField {...params} label={label} error={error} />}
            multiple={multiple}
            loading={isLoading}
            key={id}
            value={value}
            disableClearable={disableClearable}
            getOptionLabel={(option) => {
                if (Array.isArray(option)) {
                    return option[0].toString();
                }

                if (option?.label) {
                    return option?.label?.toString();
                }

                return option.toString();
            }}
        />
    );
};

export default SearchInput;
