import {
    Card,
    CardHeader,
    Container,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "src/components/Footer";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { useMutation, useQuery } from "react-query";
import { COLUMNS, changeUserStatus, getAllUsers, changeUserIsDemo } from "src/services/users";
import Header from "src/components/Header/Header";
import TableData from "src/components/Table/TableData";
import SearchBar from "src/components/SearchBar/SearchBar";
import useDebounce from "src/hooks/useDebounce";

type Props = {};

const Users = (props: Props) => {
    const [usersData, setUsersData] = useState<any>([]);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [searchValue, setSearchValue] = useState("");
    const [count,setCount] = useState()

    const pageHandler = () => {
        if (page !== 1) {
            setPage(1);
        }
    };

    const debouncedSearchTerm = useDebounce(searchValue, 500, pageHandler)

    const {  refetch: refetchUsers,isFetching } = useQuery(
        ["users", debouncedSearchTerm],
        () => getAllUsers(page, limit, debouncedSearchTerm),
        {
            enabled: false,
            onSuccess: (data: any) => {
                setUsersData(data?.data);
            },
        }
    );

    const { isLoading: userStatusLoading, mutate: userStatusMutate } = useMutation(
        ["usersStatus"],
        (data: any) =>
            changeUserStatus(`/User/change_user_status/?user_id=${data?.id}&Status=${data?.status}`),
        {
            onSuccess: (data) => {
                refetchUsers();
            },
        }
    );

    const { isLoading: userIsDemoLoading, mutate: userIsDemoMutate } = useMutation(
        ["usersIsDemo"],
        (data: any) =>
            changeUserIsDemo(`/User/change_demo_account_status/?user_profile_id=${data?.id}`),
        {
            onSuccess: (data) => {
                refetchUsers();
            },
        }
    );

    useEffect(() => {
        refetchUsers()
    }, [page, limit, debouncedSearchTerm])

    const handleStatusChange = (data) => {
        userStatusMutate(data);
    }

    const handleIsDemoChange = (data) => {
        userIsDemoMutate(data);
    }

    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>
            <PageTitleWrapper>
                <Header
                    label={"Users"}
                    name={"Users"}
                    disabled={true}
                    showBtn={false}
                />
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                                <SearchBar
                                    placeholder="Search by User name"
                                    value={searchValue}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                    }}
                                />
                            

                        {/* <Card>
                            <CardHeader title="Users" />
                            <Divider />

                            {usersData.count > 0 ? (
                                <TableData
                                    columns={COLUMNS}
                                    data={usersData}
                                    page={page}
                                    setPage={setPage}
                                    limit={limit}
                                    setLimit={setLimit}
                                    editLabel={"Edit User"}
                                    deleteLabel={"Delete User"}
                                    disabled={true}
                                    usersStatusBtn={handleStatusChange}
                                    loading={userStatusLoading}
                                    usersIsDemoBtn={handleIsDemoChange}
                                    loadingIsDemo={userIsDemoLoading}
                                    showActionBtn={false}
                                    isFetching={isFetching}
                                />
                            ) : (
                                    <Typography
                                        variant="h6"
                                        align="center"
                                        color="textSecondary"
                                        style={{ padding: "20px 0" }}
                                    >
                                        No search found
                                    </Typography>
                            )}
                        </Card> */}

                        <Card>
                            <CardHeader title="Users" />
                            <Divider />
                            <TableData
                                columns={COLUMNS}
                                data={usersData}
                                page={page}
                                setPage={setPage}
                                limit={limit}
                                setLimit={setLimit}
                                editLabel={"Edit User"}
                                deleteLabel={"Delete User"}
                                disabled={true}
                                usersStatusBtn={handleStatusChange}
                                loading={userStatusLoading}
                                usersIsDemoBtn={handleIsDemoChange}
                                loadingIsDemo={userIsDemoLoading}
                                showActionBtn={false}
                                isFetching={isFetching}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
};

export default Users;
