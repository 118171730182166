import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Container,
    Divider,
    Grid,
    TextField,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import Header from "src/components/Header/Header";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import Footer from "src/components/Footer";
import { useMutation, useQuery } from "react-query";
import {
    COLUMNS,
    getMonthlyListenerSpotify,
    addMonthlyListenerSpotify,
    updateMonthlyListenerSpotify,
    deleteMonthlyListenerSpotify,
} from "src/services/monthlyListenerSpotify";
import TableData from "src/components/Table/TableData";
import Loader from "src/components/Loader/Loader";
import Modal from "src/components/Modal/Modal";
import { isValidInput, isValidNumber } from "src/services/common";
import { openSnackbar } from "src/redux/snackbar";
import { useDispatch } from "react-redux";
import DeleteConformation from "src/components/Delete/DeleteConformation";

type Props = {};

const MonthlyListenerSpotify = (props: Props) => {
    const [monthlyListenerSpotify, setMonthlyListenerSpotify] = useState([]);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [isOpen, setIsOpen] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const dispatch = useDispatch();

    const [monthlyListener, setMonthlyListener] = useState({
        start_range: "",
        end_range: "",
        weight: "",
    });

    const [monthlyListenerSpotifyId, setMonthlyListenerSpotifyId] =
        useState<string>("");
    const [totalRecords, setTotalRecords] = useState<number>(0)
    const [deletedItem, setDeletedItem] = useState<string>("");

    const resetForm = () => {
        setMonthlyListener({
            start_range: "",
            end_range: "",
            weight: "",
        });
    };

    const {
        isLoading: monthlyListenerSpotifyLoading,
        refetch: reFetchMonthlyListenerSpotify,
        isFetching,
    } = useQuery(
        ["LegacyArtistSportifyPlays"],
        () => getMonthlyListenerSpotify(page, limit),
        {
            enabled:false,
            onSuccess: (data) => {
                setTotalRecords(data?.count)
                setMonthlyListenerSpotify(data);
                if (deletedItem !== "") {
                    setDeletedItem("")
                }
            },
        }
    );

    useEffect(() => {
        reFetchMonthlyListenerSpotify()
    }, [page, limit]);


    const { mutate, isLoading, reset } = useMutation(
        (data: any) => addMonthlyListenerSpotify(data),
        {
            onError: (error:any) => {
                dispatch(
                    openSnackbar({
                        message: error?.response?.data?.error,
                        title: "Error",
                        type: "error",
                    })
                );

                reset();
            },
            onSuccess: () => {
                dispatch(
                    openSnackbar({
                        message: "Monthly Listener Spotify Created Successfully! ",
                        title: "Success",
                        type: "success",
                    })
                );
                resetForm();
                setIsOpen(false);
                reFetchMonthlyListenerSpotify();
                setIsSubmitted(false);
            },
        }
    );

    const { mutate: deleteMutate, reset: deleteReset } = useMutation(
        (id: string) => deleteMonthlyListenerSpotify(id),
        {
            onError: (error) => {
                dispatch(
                    openSnackbar({
                        message: error,
                        title: "Error",
                        type: "error",
                    })
                );

                reset();
            },

            onSuccess: () => {
                dispatch(
                    openSnackbar({
                        message: "Monthly Listener Spotify Deleted Successfully! ",
                        title: "Success",
                        type: "success",
                    })
                );
                deleteReset();
                if (totalRecords % limit === 1 && page > 1) {
                    setPage((prevPage) => prevPage - 1);
                } else {
                    reFetchMonthlyListenerSpotify();
                }
            },
        }
    );

    const {
        mutate: update,
        isLoading: loadingUpdate,
        reset: updateReset,
    } = useMutation(
        (data: any) => updateMonthlyListenerSpotify(data, monthlyListenerSpotifyId),
        {
            onError: (error:any) => {
                dispatch(
                    openSnackbar({
                        message: error?.response?.data?.error,
                        title: "Error",
                        type: "error",
                    })
                );

                updateReset();
            },

            onSuccess: () => {
                updateReset();
                dispatch(
                    openSnackbar({
                        message: "Monthly Listener Spotify Updated Successfully! ",
                        title: "Success",
                        type: "success",
                    })
                );
                setOpenUpdateModal(false);
                reFetchMonthlyListenerSpotify();
                resetForm();
                setIsSubmitted(false);
            },
        }
    );

    const handleDelete = async (id: string) => {
        const resp = await DeleteConformation();

        if (resp) {
            setDeletedItem(id)
            deleteMutate(id);
        }
    };

    const preventSign = (e) => {
        if (e.code === 'Minus' || e.code === "Equal" || e.code === "NumpadSubtract" || e.code === "NumpadAdd") {
            e.preventDefault();
        }
    };

    const InputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const maxRange = BigInt("9223372036854775807")

        const exceedMaxRange = (val) => {
            const bigIntValue = BigInt(val)

            if(bigIntValue > maxRange){
                return false
            }else{
                return true
            }

        }

        if (
            (name === "start_range" && (!exceedMaxRange(value) )) ||
            (name === "end_range" && (!exceedMaxRange(value) ))
        ) {
            return;
        }
        setMonthlyListener((prevValue) => ({
            ...prevValue,
            [name]: value === "" ? "" : parseFloat(value),
        }));
    };

    const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitted(true);

        const startRange = monthlyListener.start_range
        const endRange = monthlyListener.end_range
        const weight = monthlyListener.weight
        if (
            startRange === ""    ||
            endRange === "" ||
            weight === ""  
        ) {
            return;
        }

        if (!isValidNumber(startRange)) {
            dispatch(
                openSnackbar({
                    message: "The value for start range can't be negative.",
                    title: "Error",
                    type: "error",
                })
            );
            return
        } else if (!isValidNumber(endRange)) {
            dispatch(
                openSnackbar({
                    message: "The value for end range can't be negative.",
                    title: "Error",
                    type: "error",
                })
            );
            return
        } else if (Number(weight) < 0) {
            dispatch(
                openSnackbar({
                    message: "The value for weight can't be negative.",
                    title: "Error",
                    type: "error",
                })
            );
            return
        }
        // } else if (!isValidNumber(weight)) {
        //     dispatch(
        //         openSnackbar({
        //             message: "The value for weight can't be negative.",
        //             title: "Error",
        //             type: "error",
        //         })
        //     );
        //     return
        // }

        
        openUpdateModal ? update(monthlyListener) : mutate(monthlyListener);
    };

    return (
        <>
            <Helmet>
                <title>Monthly Listeners Spotify</title>
            </Helmet>
            <PageTitleWrapper>
                <Header
                    label={"Legacy Artist Monthly Listeners"}
                    btnName={"Add New"}
                    name={"Legacy Artist Monthly Listeners"}
                    setIsopen={() => {
                        setIsOpen(true);
                        resetForm();
                    }}
                />
            </PageTitleWrapper>

            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        {monthlyListenerSpotifyLoading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "40vh",
                                    alignItems: "center",
                                }}
                            >
                                <Loader />
                            </Box>
                        ) : (
                            <Card>
                                    <CardHeader title="Legacy Artist Monthly Listeners" />
                                <Divider />
                                <TableData
                                    columns={COLUMNS}
                                    data={monthlyListenerSpotify}
                                    page={page}
                                    setPage={setPage}
                                    limit={limit}
                                    setLimit={setLimit}
                                    onDelete={handleDelete}
                                    openUpdateModal={setOpenUpdateModal}
                                    setUpdatedId={setMonthlyListenerSpotifyId}
                                    setUpdatedState={(item) => {
                                        setMonthlyListener({
                                            start_range: item.start_range.toString(),
                                            end_range: item.end_range.toString(),
                                            weight: item.weight.toString(),
                                        });
                                    }}
                                    editLabel={"Edit Legacy Artist Monthly Listener"}
                                    deleteLabel={"Delete Legacy Artist Monthly Listener"}
                                    deletedItem={deletedItem}
                                    isFetching={isFetching}
                                />
                            </Card>
                        )}
                    </Grid>
                </Grid>
            </Container>
            
            <Footer />

            <Modal
                open={isOpen || openUpdateModal}
                onClose={() => {
                    openUpdateModal ? setOpenUpdateModal(false) : setIsOpen(false);
                }}
                title={`${openUpdateModal
                    ? "Update  Legacy Artist Monthly Listener"
                    : "Add New Legacy Artist Monthly Listener"
                    }`}
            >
                <Box
                    component="form"
                    onSubmit={submitHandler}
                    noValidate
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "350px",
                        margin: "auto",
                        paddingX: "10px"
                    }}
                >
                    <TextField
                        autoComplete="off"
                        margin="normal"
                        required
                        fullWidth
                        label="Start range"
                        name="start_range"
                        autoFocus
                        type="number"
                        onChange={InputChangeHandler}
                        error={
                            isSubmitted && (monthlyListener?.start_range=== "" || !isValidNumber(monthlyListener?.start_range))
                        }
                        value={monthlyListener.start_range}
                        onKeyDown={preventSign}
                    />
                    <TextField
                        autoComplete="off"
                        margin="normal"
                        required
                        fullWidth
                        label="End range"
                        name="end_range"
                        type="number"
                        onChange={InputChangeHandler}
                        error={isSubmitted && (monthlyListener?.end_range === "" || !isValidNumber(monthlyListener?.end_range))}
                        value={monthlyListener.end_range}
                        onKeyDown={preventSign}
                    />
                    <TextField
                        autoComplete="off"
                        margin="normal"
                        required
                        fullWidth
                        label="Weight"
                        name="weight"
                        type="number"
                        onChange={InputChangeHandler}
                        sx={{
                            marginBottom: "20px",
                        }}
                        // error={isSubmitted && (monthlyListener?.weight === "" || !isValidNumber(monthlyListener?.weight))}
                        error={isSubmitted && (monthlyListener.weight === "" || Number(monthlyListener.weight) < 0)}

                        value={monthlyListener.weight}
                        onKeyDown={preventSign}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            marginBottom: "20px",
                        }}
                    >
                        {(() => {
                            if (isLoading || loadingUpdate) {
                                return <Loader color={"inherit"} size={20} />;
                            } else if (openUpdateModal) {
                                return "Update";
                            } else {
                                return "Add";
                            }
                        })()}
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default MonthlyListenerSpotify;
