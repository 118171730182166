import { Suspense, lazy } from "react";

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";

import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";
import GeographyFactor from './content/pages/GeographyFactor/GeographyFactor';
import InstaFollowersFactor from "./content/pages/InstaFollowersFactor/InstaFollowersFactor";
import MonthelyListnesFactor from "./content/pages/MonthelyListnesFactor/MonthelyListnesFactor";
import Notifications from "./content/pages/Notifications/Notifications";
import MonthlyListenerSpotify from "./content/pages/MonthlyListenerSpotify/MonthlyListenerSpotify";
import Users from "./content/pages/users/Users";
const BasePrice = Loader(
  lazy(() => import("src/content/pages/base_price/BasePrice"))
);
const ArtistSpotifyWeight = Loader(
  lazy(
    () => import("src/content/pages/artistSpotifyWeight/ArtistSpotifyWeight")
  )
);
const InstaFollowersWeight = Loader(
  lazy(
    () => import("src/content/pages/InstaFollowersWeight/InstaFollowersWeight")
  )
);
const SongPopularityWeight = Loader(
  lazy(
    () => import("src/content/pages/songPopularityWeight/SongPopularityWeight")
  )
);
const LegacyArtistSpotifyPlays = Loader(
  lazy(
    () =>
      import(
        "src/content/pages/legacyArtistSpotifyPlays/LegacyArtistSpotifyPlays"
      )
  )
);

// Pages

const Overview = Loader(lazy(() => import("src/content/overview")));

// Dashboards

const Dashboard = Loader(lazy(() => import("src/content/dashboards/Crypto")));
const SyncHistory = Loader(
  lazy(() => import("src/content/pages/SyncHistory/SyncHistory"))
);
// Applications

const Messenger = Loader(
  lazy(() => import("src/content/applications/Messenger"))
);
const Transactions = Loader(
  lazy(() => import("src/content/applications/Transactions"))
);
const UserProfile = Loader(
  lazy(() => import("src/content/applications/Users/profile"))
);
const UserSettings = Loader(
  lazy(() => import("src/content/applications/Users/settings"))
);

const Login = Loader(lazy(() => import("src/content/pages/login/Login")));
const Register = Loader(
  lazy(() => import("src/content/pages/register/Register"))
);

const ArtistWillingness = Loader(
  lazy(() => import("./content/pages/artistWillingness/ArtistWillingness"))
);

const Artist = Loader(lazy(() => import("./content/pages/artist/Artist")));
const Brand = Loader(lazy(() => import("./content/pages/brand/Brand")));
const BrandWeight = Loader(
  lazy(() => import("./content/pages/brandweight/BrandWeight"))
);
const BrandCategories = Loader(
  lazy(() => import("./content/pages/brandcategories/BrandCategories"))
);
const Territory = Loader(
  lazy(() => import("./content/pages/territory/Territory"))
);
const Region = Loader(lazy(() => import("./content/pages/region/Region")));
const Geography = Loader(
  lazy(() => import("./content/pages/geography/Geography"))
);
const Country = Loader(lazy(() => import("./content/pages/country/Country")));
const Duration = Loader(
  lazy(() => import("./content/pages/duration/Duration"))
);
const Exclusivity = Loader(
  lazy(() => import("./content/pages/exclusivity/Exclusivity"))
);
const CoverVersion = Loader(
  lazy(() => import("./content/pages/cover_version/Coverversion"))
);
const LyricAdjustment = Loader(
  lazy(() => import("./content/pages/lyricadjustment/LyricAdjustment"))
);
const Media = Loader(lazy(() => import("./content/pages/media/Media")));
const MediaFactor = Loader(
  lazy(() => import("./content/pages/MediaFactor/MediaFactor"))
);
const SubMedia = Loader(
  lazy(() => import("./content/pages/submedia/SubMedia"))
);
const Tags = Loader(lazy(() => import("./content/pages/tags/Tag")));
const Campaigns = Loader(
  lazy(() => import("./content/pages/campaigns/Campaigns"))
);

const Artists = Loader(lazy(() => import("./content/pages/artist/Artist")));

const EstimatesByCampaign = Loader(
  lazy(() => import("./content/pages/estimates/EstimatesByCampaign"))
);

const EstimateDetails = Loader(
  lazy(() => import("./content/pages/EstimateDetails/EstimateDetails"))
);
// Components

const Buttons = Loader(
  lazy(() => import("src/content/pages/Components/Buttons"))
);
const Modals = Loader(
  lazy(() => import("src/content/pages/Components/Modals"))
);
const Accordions = Loader(
  lazy(() => import("src/content/pages/Components/Accordions"))
);
const Tabs = Loader(lazy(() => import("src/content/pages/Components/Tabs")));
const Badges = Loader(
  lazy(() => import("src/content/pages/Components/Badges"))
);
const Tooltips = Loader(
  lazy(() => import("src/content/pages/Components/Tooltips"))
);
const Avatars = Loader(
  lazy(() => import("src/content/pages/Components/Avatars"))
);
const Cards = Loader(lazy(() => import("src/content/pages/Components/Cards")));
const Forms = Loader(lazy(() => import("src/content/pages/Components/Forms")));

// Status

const Status404 = Loader(
  lazy(() => import("src/content/pages/Status/Status404"))
);
const Status500 = Loader(
  lazy(() => import("src/content/pages/Status/Status500"))
);
const StatusComingSoon = Loader(
  lazy(() => import("src/content/pages/Status/ComingSoon"))
);
const StatusMaintenance = Loader(
  lazy(() => import("src/content/pages/Status/Maintenance"))
);

const routes: RouteObject[] = [
  {
    path: "auth",
    element: <BaseLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
      },

      // {
      //   path: "register",
      //   element: <Register />,
      // },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
  {
    path: "status",
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="404" replace />,
      },
      {
        path: "404",
        element: <Status404 />,
      },
      {
        path: "500",
        element: <Status500 />,
      },
      {
        path: "maintenance",
        element: <StatusMaintenance />,
      },
      {
        path: "coming-soon",
        element: <StatusComingSoon />,
      },
    ],
  },
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "/sync/history",
        element: <SyncHistory />,
      },

      {
        path: "campaigns",
        element: <Campaigns />,
      },
      {
        path: "artists",
        element: <Artists />,
      },
      {
        path: "estimates/:id",
        element: <EstimatesByCampaign />,
      },
      {
        path: "estimates/:id/estimatedetails/:eid",
        element: <EstimateDetails />,
      },
      {
        path: "artist-willingness",
        element: <ArtistWillingness />,
      },
      {
        path: "artist-monthly-listeners",
        element: <ArtistSpotifyWeight />,
      },
      // {
      //   path: "song-popularity-weight",
      //   element: <SongPopularityWeight />,
      // },
      {
        path: "spotify-all-time-plays",
        element: <SongPopularityWeight />,
      },
      {
        path: "instagram-followers-weight",
        element: <InstaFollowersWeight />,
      },
      // {
      //   path: "legacy-artist-spotify-plays",
      //   element: <LegacyArtistSpotifyPlays />,
      // },
      {
        path: "legacy-spotify-all-time-plays",
        element: <LegacyArtistSpotifyPlays />,
      },
      {
        path: "legacy-artist-monthly-listeners",
        element: <MonthlyListenerSpotify />,
      },
      {
        path: "brand",
        element: <Brand />,
      },
      {
        path: "brandweight",
        element: <BrandWeight />,
      },
      {
        path: "brandcategories",
        element: <BrandCategories />,
      },
      {
        path: "territory",
        element: <Territory />,
      },
      {
        path: "region",
        element: <Region />,
      },
      {
        path: "geography",
        element: <Geography />,
      },
      {
        path: "country",
        element: <Country />,
      },
      {
        path: "geography-factor",
        element: <GeographyFactor />
      },
      {
        path: "duration",
        element: <Duration />,
      },
      {
        path: "exclusivity",
        element: <Exclusivity />,
      },
      {
        path: "cover_version",
        element: <CoverVersion />,
      },
      {
        path: "lyric_adjustment",
        element: <LyricAdjustment />,
      },
      {
        path: "instafollowers-factor",
        element: <InstaFollowersFactor />,
      },
      {
        path: "monthely-listeners-factor",
        element: <MonthelyListnesFactor />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "media",
        element: <Media />,
      },
      {
        path: "sub_media",
        element: <SubMedia />,
      },
      {
        path: "media-factor",
        element: <MediaFactor />,
      },
      {
        path: "tags",
        element: <Tags />,
      },
      {
        path: "artists",
        element: <Artist />,
      },
      {
        path: "base_price",
        element: <BasePrice />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
];

export default routes;
